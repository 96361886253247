export default {
  footer: {
    footerTopLogos: [],
    emailSubscription: true,
    paymentsImages: [
      {
        src: '/images/no-responsive-images/payments.svg',
        alt: 'Akceptujemy płatności: mastercard, visa, payu, dpd',
        width: 350,
        height: 40,
      },
    ],
    suklImages: false,
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 0,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: true,
    footerCertificateText: '',
    footerCertificateTextClass: '',
    footerCertificateImages: [
      {
        src: '/images/footer/sukl-vet-pl.png',
        alt: 'SUKL VET',
        link: 'https://pasze.wetgiw.gov.pl/otc/demo/index.php?jezyk=1',
        width: 120,
        height: 100,
      },
    ],
    flags: false,
    mobileApplications: false,
  },
}
