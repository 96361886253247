import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: 'j1m739581djfe7i94krtm3lj',
  dataLayerName: 'dataLayerV2',
  persooEnabled: true,

  persooOfferIdAlternatives: 'b6fce3e348074d70938b4b2891ada28a',
  persooOfferIdBasket: 'a30008bf5d9240c2a38089bc5a437203',
  persooOfferIdLastViewed: 'f7de490de78f482f8f31f6fa40aa5b14',
  persooOfferIdNotFound: 'd39d6cff492740e2802238dad6507c5c',
  persooOfferIdPreBasket: 'af6db9d9a33540ad9c9a1986eaf93016',

  persooPreBasketLimit: 6,
  persooNotFoundLimit: 6,
  ...config,
})
