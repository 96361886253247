import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import marketplace from './marketplace.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...termsPages,
  ...paymentCodes,
  ...marketplace,
  companyDetailFieldsOrder: {
    crn: 4,
    vatId: 1,
  },
  defaultAgreements: (agreementsTopic) => [
    {
      channel: 'PD',
      topic: agreementsTopic,
      value: 'N',
    },
  ],
  agreementsFilterForPlaceOrder: ({agreements = [], existingAgreements = [], accountType = ''} = {}) => {
    // scenario depends on the existing user consents and account type: ticket: 76516
    const existingEmailEshop = existingAgreements.find(({ channel, topic }) => channel === 'EMAIL' && topic === 'ESHOP')?.value ?? 'N'
    const existingEmailCp = existingAgreements.find(({ channel, topic }) => channel === 'EMAIL' && topic === 'CP')?.value ?? 'N'
    const newEmailEshop = agreements.find(({ channel, topic }) => channel === 'EMAIL' && topic === 'ESHOP')?.value ?? 'N'

    if(accountType === 'O' && existingEmailEshop === 'N' && existingEmailCp === 'N') {
      return [
        ...agreements.filter(({ channel, topic }) => !(['EMAIL', 'SMS'].includes(channel) && ['ESHOP', 'CP'].includes(topic))),
        {channel: 'EMAIL', topic: 'ESHOP', value: newEmailEshop},
        {channel: 'EMAIL', topic: 'CP', value: newEmailEshop},
        {channel: 'SMS', topic: 'ESHOP', value: newEmailEshop},
        {channel: 'SMS', topic: 'CP', value: newEmailEshop}
      ]
    }

    if(accountType === 'E' && existingEmailEshop === 'N') {
      return [
        ...agreements.filter(({ channel, topic }) => !(['EMAIL', 'SMS'].includes(channel) && topic === 'ESHOP')),
        {channel: 'EMAIL', topic: 'ESHOP', value: newEmailEshop},
        {channel: 'SMS', topic: 'ESHOP', value: newEmailEshop}
      ]
    }

    return agreements
  },


  firstPartySellerName: 'Dr. Max',
  paymentFailedMessageComponent: 'simple',
  shippingInfoBlocks: '',
  thankYouText: {
    payment: 'email',
    dispatch: 'email',
  },
  ...config,
})
