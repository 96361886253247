import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: '7570922c514785e6a75146cbb9304b35',
  analyticsApiKey: '1691d79f1ae093e791073099a6258041',
  ...config,
})
