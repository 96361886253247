import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  allowedSources: [
    '*.algolia.net',
    '*.algolianet.com',
    '*.clarity.ms',
    '*.drmax-gl.dev',
    '*.drmax-gl.space',
    '*.drmax-pl.space',
    '*.drmax.net',
    '*.drmax.zone',
    '*.drmax-gl.live',
    '*.drmaxdrogeria.pl',
    '*.facebook.com',
    '*.facebook.net',
    '*.fbcdn.net',
    '*.google-analytics.com',
    '*.google.com',
    '*.googleapis.com',
    '*.instagram.com',
    '*.sentry.io',
    '*.twitter.com',
    'action.metaffiliation.com',
    'api.luigisbox.com',
    'api.retargeted.co',
    'bam.eu01.nr-data.net',
    'bat.bing.com',
    'bbd-tag.de',
    'cdn.evgnet.com',
    'cdn.jsdelivr.net',
    'cdn.luigisbox.com',
    'cdn.preciso.net',
    'cdn.speedcurve.com',
    'cdn-4.convertexperiments.com',
    'cdp.drmax.meiro.io',
    'cdp.drmaxpl.meiro.io',
    'ck.tangooserver.com',
    'cm.g.doubleclick.net',
    'consent.cookiebot.com',
    'consentcdn.cookiebot.com',
    'delivery.clickonometrics.pl',
    'drmaxbdcsro.germany-2.evergage.com',
    'drmaxpoldroepmhub.azurewebsites.net',
    'drmaxpoldroepmhubtest.azurewebsites.net',
    'dynamic.criteo.com',
    'embed.staffino.com',
    'fledge.eu.criteo.com',
    'fonts.gstatic.com',
    'gum.criteo.com',
    'image-resizer-svc.drmax-gl.live',
    'image-resizer-svc.drmax-gl.space',
    'insights.algolia.io',
    'js-agent.newrelic.com',
    'measurement-api.criteo.com',
    'my-account-server.drmax.pl',
    'pagead2.googlesyndication.com',
    'pickup-places.drmax.pl',
    'placement-service.drmax-gl.live',
    'placement-service.drmax-gl.space',
    'profiling.clickonometrics.pl',
    'rd.afftrack.pro',
    'rtp.persoo.ai',
    's.retargeted.co',
    'scripts.persoo.cz',
    'search-service.drmax-gl.space',
    'social.rockettrack.pro',
    'static.clickonometrics.pl',
    'static.cloudflareinsights.com',
    'static.criteo.net',
    'sync.clickonometrics.pl',
    'td.doubleclick.net',
    'tpc.googlesyndication.com',
    'trk.datnova.com',
    'unpkg.com',
    'www.drmaxdrogeria.pl',
    'www.googleoptimize.com',
    'www.googletagmanager.com',
    'www.gstatic.com',
    'www.recaptcha.net',
    'www.youtube-nocookie.com',
    'www.youtube.com',
  ],
})
