export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      pickup_place_cc: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Otrzymane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nieopłacone',
          text: 'W celu opłacenia zamówienia lub uzyskania więcej informacji, skontaktuj się z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Opłacone',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'W realizacji',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Wysłane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Dostarczone',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Anulowane',
          text: 'Jeśli chcesz uzyskać więcej informacji na temat zamówienia, skontaktuj się z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Niedostarczone',
          text: 'Zamówienie nie zostało dostarczone, dlatego jest transportowane do magazynu. W razie jakichkolwiek wątpliwości prosimy o kontakt z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      delivery: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Otrzymane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nieopłacone',
          text: 'W celu opłacenia zamówienia lub uzyskania więcej informacji, skontaktuj się z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Opłacone',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'W realizacji',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Wysłane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Dostarczone',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Anulowane',
          text: 'Jeśli chcesz uzyskać więcej informacji na temat zamówienia, skontaktuj się z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Niedostarczone',
          text: 'Zamówienie nie zostało dostarczone, dlatego jest transportowane do magazynu. W razie jakichkolwiek wątpliwości prosimy o kontakt z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      reservation: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Otrzymane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'W realizacji',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Gotowe do odbioru',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'picked_up',
          label: 'Odebrane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Anulowane',
          text: 'Jeśli chcesz uzyskać więcej informacji na temat zamówienia, skontaktuj się z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119890">+48 71 711 98 90</a> lub adresem e-mail <a href="mailto:drogeria@drmax.com.pl">drogeria@drmax.com.pl</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      'pharmacy_purchase': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Otrzymane',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Dostarczone',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Anulowane',
          text: 'Jeśli chcesz uzyskać więcej informacji na temat zamówienia, skontaktuj się z naszym centrum obsługi klienta pod numerem telefonu <a href="tel:+48717119888">+ 48 71 711 98 88</a> lub adresem e-mail <a href="mailto:rezerwacje@drmax.com.pl">rezerwacje@drmax.com.pl</a>.',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
