export default defineAppConfig({
  ssrCache: {
    metadata: {
      be: {
        code: 'magento',
        region: 'POL'
      },
      fe: {
        businessUnit: 'POLDRO',
        code: 'nsf-drogeria',
        region: 'POL'
      }
    }
  }
});